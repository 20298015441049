@import "bootstrap/bootstrap";
@import url(http://fonts.googleapis.com/css?family=Lato:300);

@btn-font-weight: 300;
@font-alternate: "Lato", Helvetica, Arial, sans-serif;

@color-primary: #17365D;
@color-alternate: #4D627C;
@color-secondary: #FF0000;

@vertical-base: 7px;
@horizontal-base: 5px;

html, body {
  height: 100%;
}

body {
  font-size: 1.7em;
  font-weight: 300;
  background: #FEFEFE;
}

.page-wrap {
  min-height: 100%;
  margin-bottom: -(@vertical-base * 22);
  padding-bottom: @vertical-base * 22;
}

header {
  margin-bottom: @vertical-base * 4;

  .brand {
    padding-top: 0;
    padding-bottom: @vertical-base * 4;
    border-bottom: 3px solid @color-primary;
    h1 {
      margin: 0;
      line-height: 1;
      text-align: center;
    }
    a {
    }
    img {
      width: 330px;
    }
    small {
      display: block;
      position: relative;
      white-space: nowrap;
      top: 0;
      left: -30px;
      font-size: 17px;
      font-family: @font-alternate;
      color: @color-primary;
    }
  }
}

nav.master {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  li {
    display: inline-block;
    a {
      display: inline-block;
      padding: 7px 15px;
      font-weight: 700;
      color: @color-primary;
      border-top: 3px solid transparent;
      &:hover {
        text-decoration: none;
        border-color: @color-secondary;
      }
      &:focus {
        text-decoration: none;
      }
    }
    &.active {
      a {
        border-color: @color-primary;
      }
    }
  }
}

footer {
  height: @vertical-base * 15;
  padding-top: @vertical-base * 3;
  margin-top: @vertical-base * 7;
  font-size: .9em;
  background: @color-primary;
  color: #DDD;
  address {
    a {
      color: #FFF;
      &:hover {
        color: #FFF;
      }
    }
  }
}

.page-title {
  margin-bottom: @vertical-base * 4;
  font-family: @font-alternate;
}

.brands {
  .logo {
	display: table;
	margin: 0 auto;
    height: @vertical-base * 15;
    text-align: center;
    vertical-align: middle;
    a {
		display: table-cell;
		vertical-align: middle;
	}
    img {
      max-width: 100%;

    }
  }
}

.contact {
	address {
		float: left;
		margin-right: 15px;
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min)
{
  header .brand {
    padding-top: @vertical-base * 3;
    padding-bottom: @vertical-base;
    h1 {
      line-height: 1;
      text-align: left;
    }
    a {
      float: left;
    }
    small {
      display: inline-block;
      top: 37px;
      left: 0;
      font-size: 18px;
    }
  }
  nav.master {
    ul {
      text-align: left;
    }
  }
  .contact address {
	margin-right: 30px;
  }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min)
{
  header .brand {
    small {
      font-size: 21px;
    }
  }

}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {

}

